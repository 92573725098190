import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import "./Hero.css";

const Footer = () => {
    return (
        <footer className="bg-gray-100 border-t border-b py-8 bachimg margin">
            <div className="max-w-full mx-auto px-4 sm:px-6">
                {/* Top area: Blocks */}
                <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
                    {/* 1st block */}
                    <div className="col-span-12 lg:col-span-4">
                        <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-center rounded-lg xl:w-80 mx-auto">
                            <h3 className="font-bold text-4xl mb-4">Vedant Solutions</h3>
                            <div className="text-md font-medium text-black">
                                <p>E-Mail: vedantsolutions@outlook.com</p>
                                <p> chsatish@vedantsolutions.com</p>
                                <p>      </p>
                                <p>Sai Teja Residency, Flat No:101, Navodhaya Colony, Kukatpally-Hyderabad-500072</p>
                            </div>
                        </div>
                    </div>
                    {/* 2nd block */}
                    <div className="col-span-6 md:col-span-3 lg:col-span-2 ml-7 mx-auto">
                        <h6 className="text-white text-xl font-bold mb-4">LINKS</h6>
                        <ul className="text-md">
                            <li className="mb-2">
                                <HashLink to="#about" className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">About</HashLink>
                            </li>
                            <li className="mb-2">
                                <HashLink to="#services" className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Services</HashLink>
                            </li>
                            <li className="mb-2">
                                <Link to="/contact" className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Contact</Link>
                            </li>
                        </ul>
                    </div>
                    {/* 3rd block */}
                    <div className="col-span-6 md:col-span-4 lg:col-span-3 mx-auto">
                        <h6 className="text-white text-xl font-bold mb-4">OUR SERVICES</h6>
                        <ul className="text-md">
                        <li className="mb-2">
                                <HashLink to="#services" className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Software Development</HashLink>
                            </li>
                        <li className="mb-2">
                                <HashLink to="#services" className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Web Development</HashLink>
                            </li>
                        <li className="mb-2">
                                <HashLink to="#services" className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Digital Marketing</HashLink>
                            </li>
                        <li className="mb-2">
                                <HashLink to="#services" className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Logo Creation</HashLink>
                            </li>
                         
                            <li className="mb-2">
                                <HashLink to="#services" className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Biometric Access</HashLink>
                            </li>
                            <li className="mb-2">
                                <HashLink to="#services" className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">CC Camera Services</HashLink>
                            </li>
                        </ul>
                    </div>
                    {/* 4th block */}
                    <div className="col-span-12 lg:col-span-3 mx-auto text-center font-bold uppercase text-white">
                        <div className="text-xl mb-6">Social Media Links</div>
                        <div className="text-md font-medium mb-6">Follow us on social media</div>
                        <div className="flex justify-center mt-2">
                            <ul className="flex space-x-4">
                                <li>
                                    <a href='https://x.com/ASKVEDANT?t=a5LPsBUdTq26oMqxWXe4xw&s=09' className="flex justify-center items-center text-black hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.facebook.com/share/rPRvjxMt4ruFLBgR/?mibextid=qi2Omg' className="flex justify-center items-center text-black hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.instagram.com/vedantsolutions?utm_source=qr&igsh=MTl2eGNzc2JidnZiNw==' className="flex justify-center items-center text-black hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Instagram">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 10.7c-3 0-5.3 2.3-5.3 5.3s2.3 5.3 5.3 5.3 5.3-2.3 5.3-5.3-2.3-5.3-5.3-5.3zm0 8.7c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4zm6.4-9.8c0 .7-.6 1.2-1.2 1.2s-1.2-.6-1.2-1.2.6-1.2 1.2-1.2 1.2.6 1.2 1.2zm3.2 1.2c-.1-2-.6-3.7-1.8-4.9-1.2-1.2-2.9-1.7-4.9-1.8-2-.1-8-.1-10 0-2 .1-3.7.6-4.9 1.8-1.2 1.2-1.7 2.9-1.8 4.9-.1 2-.1 8 0 10 0 2 .6 3.7 1.8 4.9 1.2 1.2 2.9 1.7 4.9 1.8 2 .1 8 .1 10 0 2-.1 3.7-.6 4.9-1.8 1.2-1.2 1.7-2.9 1.8-4.9.1-2 .1-8 0-10zm-2.1 12.1c-.3.8-1 1.5-1.8 1.8-1.3.5-4.4.4-5.9.4s-4.6.1-5.9-.4c-.8-.3-1.5-1-1.8-1.8-.5-1.3-.4-4.4-.4-5.9s-.1-4.6.4-5.9c.3-.8 1-1.5 1.8-1.8 1.3-.5 4.4-.4 5.9-.4s4.6-.1 5.9.4c.8.3 1.5 1 1.8 1.8.5 1.3.4 4.4.4 5.9s.1 4.6-.4 5.9z" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://in.pinterest.com/vedantsolutions/" className="flex justify-center items-center text-black hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Pinterest">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 0C7.2 0 0 7.2 0 16c0 7.4 5.1 13.7 12 15.5-.2-1.3-.4-3.2 0-4.6.4-1.4 2.7-8.7 2.7-8.7s-.7-1.4-.7-3.5c0-3.2 1.9-5.6 4.3-5.6 2 0 2.9 1.5 2.9 3.2 0 2-1.3 5-2 7.8-.6 2.1 1.2 3.9 3.3 3.9 4 0 6.7-4.1 6.7-10 0-5.2-3.7-8.8-9.1-8.8-6.2 0-10 4.6-10 9.6 0 1.9.7 3.9 1.7 5 .2.2.2.4.2.7-.2.8-.6 2.7-.7 3.1-.1.4-.3.5-.7.3-2.6-1.1-4.2-4.5-4.2-7.3 0-6.1 4.5-11.8 12.9-11.8 6.8 0 12.1 4.8 12.1 11.2 0 6.8-4.2 12.3-10 12.3-2-2-3.4-4.9-2.9-8.3z" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/in/vedant-solutions-97b26b119?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" className="flex justify-center items-center text-black hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="LinkedIn">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 12h5v2.6c.7-1.1 2.2-2.6 4.7-2.6 5 0 6 3.3 6 7.7V28h-5V21c0-2-.1-4.5-3-4.5s-3.5 2.1-3.5 4.3V28H12V12zm-8-3h5v16H4V9zM6.5 2C5.1 2 4 3.1 4 4.5S5.1 7 6.5 7 9 5.9 9 4.5 7.9 2 6.5 2z" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                    <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                        <div className="text-sm text-white font-semibold py-1">
                            Copyright &copy; 2024 {"  "}
                            <HashLink to="#" className="hover:text-gray-900">
                                Vedant Solutions
                            </HashLink>. All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
